import React, { useEffect, useState } from "react";

// Extend the global `window` object to include FB and fbAsyncInit
declare global {
  interface Window {
    fbAsyncInit?: () => void;
    FB?: {
      init: (options: any) => void;
      getLoginStatus: (callback: (response: any) => void) => void;
      login: (callback: (response: any) => void, options?: any) => void;
      logout: (callback: (response: any) => void) => void;
      api: (path: string, params: any, callback: (response: any) => void) => void;
      AppEvents?: { logPageView: () => void };
    };
  }
}

export default function Portal() {
  const [userStatus, setUserStatus] = useState<string>("unknown");
  const [userData, setUserData] = useState<{ id: string; name: string; email: string } | null>(null);

  useEffect(() => {
    const loadFacebookSDK = () => {
      if (document.getElementById("facebook-jssdk")) return;

      const script = document.createElement("script");
      script.id = "facebook-jssdk";
      script.src = "https://connect.facebook.net/en_US/sdk.js";
      script.async = true;
      script.defer = true;
      script.onload = () => {
        window.fbAsyncInit = function () {
          window.FB?.init({
            appId: "3042059625946350", // Replace with your App ID
            cookie: true,
            xfbml: true,
            version: "v22.0",
          });

          window.FB?.AppEvents?.logPageView();
          checkLoginStatus();
        };
      };

      document.body.appendChild(script);
    };

    const checkLoginStatus = () => {
      window.FB?.getLoginStatus((response) => {
        statusChangeCallback(response);
      });
    };

    loadFacebookSDK();
  }, []);

  const statusChangeCallback = (response: any) => {
    console.log("Login Status Response:", response);

    if (response.status === "connected") {
      setUserStatus("connected");
      fetchUserData(response.authResponse);
    } else if (response.status === "not_authorized") {
      setUserStatus("not_authorized");
    } else {
      setUserStatus("unknown");
    }
  };

  const fetchUserData = (authResponse: any) => {
    window.FB?.api("/me", { fields: "id,name,email" }, (userInfo: any) => {
      setUserData({
        id: userInfo.id,
        name: userInfo.name,
        email: userInfo.email,
      });
    });
  };

  const handleLogin = () => {
    window.FB?.login(
      (response) => {
        statusChangeCallback(response);
      },
      {
        config_id: "2139498743151256", // Replace with your actual config_id
        response_type: "code",
        override_default_response_type: true,
      }
    );
  };

  const handleLogout = () => {
    window.FB?.logout(() => {
      setUserStatus("unknown");
      setUserData(null);
    });
  };

  return (
    <div className="p-4">
      <h1 className="text-xl font-bold mb-4">Portal</h1>

      {userStatus === "connected" && userData ? (
        <div className="bg-gray-100 p-4 rounded">
          <p><strong>Name:</strong> {userData.name}</p>
          <p><strong>Email:</strong> {userData.email}</p>
          <button onClick={handleLogout} className="bg-red-500 text-white px-4 py-2 rounded mt-2">
            Logout
          </button>
        </div>
      ) : (
        <div>
          {userStatus === "not_authorized" ? (
            <p className="text-red-500">You are logged into Facebook but not authorized for this app.</p>
          ) : (
            <p className="text-gray-500">Please log in with Facebook.</p>
          )}
          <button onClick={handleLogin} className="bg-blue-500 text-white px-4 py-2 rounded mt-2">
            Login with Facebook
          </button>
        </div>
      )}
    </div>
  );
}

