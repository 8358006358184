import React, { useState, useRef, useEffect } from "react";
import '../../App.css';
import {
  Button,
  // DateValue,
} from "@nextui-org/react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  RadioGroup, 
  Radio,
  Link, 
  User, 
  Chip, 
  cn,
  ScrollShadow,
  Select, SelectItem,
  DatePicker,
} from "@heroui/react";
import {now, getLocalTimeZone, DateValue} from "@internationalized/date";

import ReactButton from "../../ReactButton";
import { QRCodeCanvas } from 'qrcode.react';

import { FaVideo } from "react-icons/fa6";
import { TiPin } from "react-icons/ti";
import { FaArrowRight } from "react-icons/fa";
import { FaArrowLeft } from "react-icons/fa6";

import { animateScroll as scroll } from 'react-scroll';

const List = [
  {
    name: "Bedbug Thermal Fogging",
    description: "Eliminate bedbugs at all life stages.",
    imgSrc: "bedbug.png",
    imgDescription: "Bedbug Image",
    serviceCode: "",
  },
  {
    name: "Mosquito IRS",
    description: "Exterminate mosquito populations.",
    imgSrc: "mosquito.png",
    imgDescription: "Mosquito Image",
    serviceCode: "",
  },
  {
    name: "MaxAnt",
    description: "Long-term termite protection.",
    imgSrc: "termite.png",
    imgDescription: "Termite Image",
    serviceCode: "",
  },
  {
    name: "MaxEco Cockroach Treatment",
    description: "Protect your kitchen from roaches.",
    imgSrc: "cockroach.jpg",
    imgDescription: "Cockroach Image",
    serviceCode: "",
  },
  {
    name: "Residential Pest Control",
    description: "Protect your home and family from pests.",
    imgSrc: "residential.png",
    imgDescription: "Residential Pest Control Image",
    serviceCode: "",
  },
  {
    name: "Commercial Pest Control",
    description: "Tailored solutions for businesses.",
    imgSrc: "commercial.png",
    imgDescription: "Commercial Pest Control Image",
    serviceCode: "",
  },
  {
    name: "Industrial Pest Control",
    description: "Large-scale pest management.",
    imgSrc: "industrial.png",
    imgDescription: "Industrial Pest Control Image",
    serviceCode: "",
  },
]

const houseTypes = [
  {key: "cat", label: "Cat"},
  {key: "dog", label: "Dog"},
  {key: "elephant", label: "Elephant"},
  {key: "lion", label: "Lion"},
  {key: "tiger", label: "Tiger"},
  {key: "giraffe", label: "Giraffe"},
  {key: "dolphin", label: "Dolphin"},
  {key: "penguin", label: "Penguin"},
  {key: "zebra", label: "Zebra"},
  {key: "shark", label: "Shark"},
  {key: "whale", label: "Whale"},
  {key: "otter", label: "Otter"},
  {key: "crocodile", label: "Crocodile"},
];

// Handle custom scroll behavior with smooth scroll and offset
  const handleScroll = (href: string) => {
    const target = document.querySelector(href) as HTMLElement;

    if (target) {
      const offsetTop = target.offsetTop - 100; // Offset for fixed navbar
      scroll.scrollTo(offsetTop, { duration: 500, smooth: true }); // Use react-scroll's scrollTo
    }
  };

const VideoPlayerCarousel = () => {
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const videoRef = useRef<HTMLVideoElement>(null);

  const videoList = [
    "videos/VID-20250118-WA0006.mp4",
    "videos/VID-20250118-WA0001.mp4",
    "videos/VID-20250118-WA0002.mp4",
    "videos/VID-20250118-WA0003.mp4",
    "videos/VID-20250118-WA0004.mp4",
    "videos/VID-20250118-WA0005.mp4",
    "videos/VID-20250118-WA0007.mp4",
    "videos/VID-20250118-WA0008.mp4",
  ];

  const handlePrev = () => {
    setCurrentVideoIndex((prevIndex) =>
      prevIndex === 0 ? videoList.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentVideoIndex((prevIndex) =>
      prevIndex === videoList.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePlayPause = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
    }
  };

  return (
    <div className="relative w-full h-full bg-black flex items-center justify-center">
      {/* Video Player */}
      <video
        ref={videoRef}
        src={videoList[currentVideoIndex]}
        controls
        autoPlay
        loop
        muted
        playsInline
        className="w-full h-full object-cover"
      ></video>

      {/* Left Arrow */}
      <button
        onClick={handlePrev}
        className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white p-4 rounded-[20000px] hover:bg-gray-600 z-10"
      >
        <FaArrowLeft />
      </button>

      {/* Right Arrow */}
      <button
        onClick={handleNext}
        className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white p-4 rounded-[20000px] hover:bg-gray-600 z-10"
      >
        <FaArrowRight />
      </button>

      {/* Play/Pause Button */}
      {/* <button
        onClick={handlePlayPause}
        className="absolute bottom-10 left-1/2 transform -translate-x-1/2 bg-gray-800 text-white p-4 rounded hover:bg-gray-600 z-10"
      >
        Play/Pause
      </button> */}

      {/* Current Video Indicator */}
      <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 text-white">
        {currentVideoIndex + 1} / {videoList.length}
      </div>
    </div>
  );
};

function Home() {
  const {isOpen, onOpen, onOpenChange} = useDisclosure();
  const {isOpen: isOpen2, onOpen: onOpen2, onOpenChange: onOpenChange2} = useDisclosure();
  const {isOpen: isOpen3, onOpen: onOpen3, onOpenChange: onOpenChange3} = useDisclosure();

  const [selectedService, setSelectedService] = React.useState("");

  const [activeCode, setActiveCode] = useState('');
  const [whatsappUrlCode, setWhatsappUrlCode] = useState('');
  
  var wa_number = "254784470922";

  useEffect(() => {
    let wa_msg = encodeURIComponent(`Hello, I'm interested in your ${selectedService} service.`);

    let PROMO_STORAGE_KEY = "promo_codes";

    let now = Date.now();

    // Retrieve promo codes from localStorage
    let storedPromoCodes = JSON.parse(localStorage.getItem(PROMO_STORAGE_KEY) || "[]");

    // Filter out expired promo codes
    let validPromoCodes = storedPromoCodes.filter((promo: { code: string; expiry: number }) => promo.expiry > now);

    if (validPromoCodes.length > 0) {
      // Use the first valid promo code
      setActiveCode(validPromoCodes[0].code.toUpperCase());
      wa_msg = encodeURIComponent(`Hello, I'm interested in your services.\nMy promo code is: ${activeCode}`);
    }

    setWhatsappUrlCode(`https://wa.me/${wa_number}?text=${wa_msg}`);
  });

  const onComplete = () => {
    onOpenChange();

    let activeCode;
    let wa_msg = encodeURIComponent(`Hello, I'm interested in your ${selectedService} service.`);
    var wa_number = "254784470922";

    let PROMO_STORAGE_KEY = "promo_codes";

    let now = Date.now();

    // Retrieve promo codes from localStorage
    let storedPromoCodes = JSON.parse(localStorage.getItem(PROMO_STORAGE_KEY) || "[]");

    // Filter out expired promo codes
    let validPromoCodes = storedPromoCodes.filter((promo: { code: string; expiry: number }) => promo.expiry > now);

    if (validPromoCodes.length > 0) {
      // Use the first valid promo code
      activeCode = (validPromoCodes[0].code.toUpperCase());
      wa_msg = encodeURIComponent(`Hello, I'm interested in your ${selectedService} service.\nMy promo code is: ${activeCode}`);
    }

    if (selectedService === "Become an Affiliate"){
      wa_msg = encodeURIComponent(`Hello, I want to become an affiliate.`);
    } else if (selectedService === "Join our Team") {
      wa_msg = encodeURIComponent(`Hello, I want to join your team.`);
    } else if (selectedService === "Contact Us") {
      wa_msg = encodeURIComponent(`Hello`);
    }

    const whatsappUrl = `https://wa.me/${wa_number}?text=${wa_msg}`;

    window.open(whatsappUrl, "_blank");
  };

  const CustomRadio = ({option, statusColor, value}) => {
    return (
      <Radio
        aria-label={option.name}
        classNames={{
          base: cn(
            "inline-flex max-w-xl w-full bg-content1 m-0",
            "hover:bg-content2 items-center justify-start",
            "cursor-pointer rounded-lg gap-2 p-4 border-2 border-transparent",
            "data-[selected=true]:border-primary",
          ),
          label: "w-full",
        }}
        value={value}
      >
        <div className="flex flex-row justify-stretch gap-2 w-[100%]">
          <div className="flex flex-col">
            <p>{option.name}</p>
            <span className="text-tiny text-default-500">{option.description}</span>
          </div>
          <div className="flex flex-col items-end gap-1">
            <Chip color={statusColor} size="sm" variant="flat">
              {option.status}
            </Chip>
          </div>
        </div>
      </Radio>
    );
  };

  const ServicesList = () => {
    return (
      <>
        {List.map((service) => (
          <div key={service.name} className='bg-[#f4faff] pb-[20px] pt-[14px] border border-[#8797c2] rounded-b-[30px] shadow-md'>
            <div className='flex flex-col justify-between gap-3'>
              <div className='text-start mx-[15px]'>
                <p className='text-[20px] text-[#1f262e] font-medium font-itim'>{service.name}</p>
                <p className='text-[14px] font-light'>{service.description}</p>
              </div>
              <div className='flex border-y border-[#9f9f96]'>
                <div className='relative w-full h-[300px] overflow-hidden'>
                  <img 
                  className="w-full h-[300px] absolute"
                  src={service.imgSrc}
                  alt={service.imgDescription}
                  />
                </div>
              </div>
              <div className='flex flex-row align-middle items-center justify-center gap-1 md:gap-3 font-medium font-itim'>
                <Button
                  color="primary" variant="flat" onPress={onOpen}
                  className='border-2 border-[#5a70a9] bg-[#141f29] px-5 xl:px-6 py-1 md:py-2 text-white text-2xl xl:text-3xl rounded-full'
                >SCHEDULE NOW</Button>
                <Button isIconOnly variant='faded' color="danger" className='rounded-full bg-[#478dcd] h-[48px] w-[48px]'>
                  <TiPin size={30} color='white' />
                </Button>
              </div>
            </div>
          </div>
        ))}
      </>
  )};

  return (
    <>
      <ReactButton />
      <div className='h-6'></div>
      <div className="App scroll-smooth">
      <div>
      <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">Complete your action!</ModalHeader>
              <ModalBody>
                <div className="flex flex-col gap-3">
                  <RadioGroup label="Select your preferred service" value={selectedService} onValueChange={setSelectedService}>
                    <ScrollShadow className=" h-[350px]">
                      {/* <CustomRadio
                        statusColor="secondary"
                        option={{
                          name: "Become an Affiliate",
                          description: "Earn commissions on referred sales.",
                          status: "Available",
                        }}
                        value="Become an Affiliate"
                      /> */}
                      <CustomRadio
                        statusColor="secondary"
                        option={{
                          name: "Bedbug Thermal Fogging",
                          description: "Eliminate bedbugs at all life stages.",
                          status: "Available",
                        }}
                        value="Bedbug Thermal Fogging"
                      />
                      <CustomRadio
                        statusColor="secondary"
                        option={{
                          name: "Mosquito IRS",
                          description: "Exterminate mosquito populations.",
                          status: "Available",
                        }}
                        value="Mosquito IRS"
                      />
                      <CustomRadio
                        statusColor="secondary"
                        option={{
                          name: "MaxAnt",
                          description: "Long-term termite protection.",
                          status: "Available",
                        }}
                        value="MaxAnt"
                      />
                      <CustomRadio
                        statusColor="secondary"
                        option={{
                          name: "MaxEco Cockroach Treatment",
                          description: "Protect your kitchen from roaches.",
                          status: "Available",
                        }}
                        value="MaxEco Cockroach Treatment"
                      />
                      <CustomRadio
                        statusColor="secondary"
                        option={{
                          name: "Residential Pest Control",
                          description: "Protect your home and family from pests.",
                          status: "Available",
                        }}
                        value="Residential Pest Control"
                      />
                      <CustomRadio
                        statusColor="secondary"
                        option={{
                          name: "Commercial Pest Control",
                          description: "Tailored solutions for businesses.",
                          status: "Available",
                        }}
                        value="Commercial Pest Control"
                      />
                      <CustomRadio
                        statusColor="secondary"
                        option={{
                          name: "Industrial Pest Control",
                          description: "Large-scale pest management.",
                          status: "Available",
                        }}
                        value="Industrial Pest Control"
                      />
                      {/* <CustomRadio
                        statusColor="secondary"
                        option={{
                          name: "Join our Team",
                          description: "Join our growing team.",
                          status: "Available",
                        }}
                        value="Join our Team"
                      />
                      <CustomRadio
                        statusColor="secondary"
                        option={{
                          name: "Contact Us",
                          description: "Talk to us right now.",
                          status: "Available",
                        }}
                        value="Contact Us"
                      /> */}
                    </ScrollShadow>
                  </RadioGroup>
                </div>
                <p className="text-default-500 text-small">Selected: {selectedService}</p>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Close
                </Button>
                <Button color="primary" isDisabled={selectedService.length === 0} onPress={onComplete}>
                  Complete
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>


      <Modal isOpen={isOpen3} onOpenChange={onOpenChange3}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">Complete your action!</ModalHeader>
              <ModalBody>
                <div className="flex flex-col gap-3">
                  <Select
                    isRequired
                    className="max-w-full"
                    defaultSelectedKeys={["cat"]}
                    label="House Type"
                    placeholder="Select a house type"
                  >
                    {houseTypes.map((house) => (
                      <SelectItem key={house.key}>{house.label}</SelectItem>
                    ))}
                  </Select>
                  <div className="w-full max-w-xl flex flex-row gap-4">
                    <DatePicker
                      hideTimeZone
                      showMonthAndYearPickers
                      // defaultValue={now(getLocalTimeZone()) as unknown as DateValue | null}
                      // defaultValue={new Date() as unknown as DateValue | null}
                      // @ts-ignore — Ignoring due to type conflict
                      defaultValue={now(getLocalTimeZone())}
                      label="Event Date"
                      variant="bordered"
                    />
                  </div>
                </div>
                <p className="text-default-500 text-small">Selected: {selectedService}</p>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Close
                </Button>
                <Button color="primary" isDisabled={selectedService.length === 0} onPress={onComplete}>
                  Complete
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>

      <Modal isOpen={isOpen2} onOpenChange={onOpenChange2}>
        <ModalContent>
          {(onClose2) => (
            <>
              <ModalHeader className="flex flex-col gap-1">See Our Work</ModalHeader>
              <ModalBody>
                <div>
                  <div>
                    <VideoPlayerCarousel />
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose2}>
                  Close
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
      </div>

      <div className='absolute top-0 left-0 w-[100%] h-[4890px] z-[-50] overflow-x-hidden'>
        <div className=''>
          <div className='absolute rounded-[3000px] w-[400px] h-[400px] left-0 mt-[0px] ml-[-375px]'>
            <img
              height={400}
              width={400}
              className=''
              src='sphere.png'
            />
          </div>

          <div className='absolute border-[80px] border-[#f8e3a6] rounded-[3000px] w-[980px] h-[980px] right-0 mt-[-300px] mr-[-250px]'></div>
          <div className='absolute border-[80px] border-[#b5dfef] rounded-[3000px] w-[980px] h-[980px] left-0 mt-[730px] ml-[-300px]'></div>

          <div className='absolute w-[250px] h-[250px] left-0 mt-[350px] md:mt-[250px] transform rotate-[70deg] md:rotate-[-70deg] ml-[-30px]'>
            <img
              height={400}
              width={400}
              className='filter grayscale brightness-0'
              src='footer-bug-left.png'
            />
          </div>

          <div className='absolute w-[600px] h-[400px] right-0 mt-[60px] md:mt-[230px] transform rotate-[-90deg] md:rotate-[70deg] mr-[-170px]'>
            <img
              height={400}
              width={600}
              className='filter grayscale brightness-0'
              src='footer-bug-right.png'
            />
          </div>

          <div className='absolute border-[80px] border-[#f8e3a6] rounded-[3000px] w-[980px] h-[980px] right-0 mt-[1760px] mr-[-300px]'></div>
          <div className='absolute border-[80px] border-[#b5dfef] rounded-[3000px] w-[980px] h-[980px] left-0 mt-[2890px] ml-[-300px]'></div>

          <div className='absolute border-[80px] border-[#f8e3a6] rounded-[3000px] w-[980px] h-[980px] right-0 mt-[3990px] mr-[-300px]'></div>
          <div className='absolute border-[80px] border-[#b5dfef] rounded-[3000px] w-[980px] h-[980px] left-0 mt-[4990px] ml-[-300px]'></div>

        </div>
      </div>
      <div className='max-w-[88%] m-[20px] mx-auto space-y-[20px]'>
        {/* <div className='bg-[#ecf4fc] mb-0 p-[16px] border border-[#8797c2] rounded-[30px]'>Navbar</div> */}
        <div className='bg-[#f4faff] overflow-hidden mb-0 px-[0px] xl:px-[42px] border border-[#8797c2] rounded-[30px] md:rounded-[50px] shadow-md'>
          <div className='flex flex-row justify-between'>
            <div className='relative py-[24px] px-[20px] space-y-8  xl:max-w-[50%]'>
              <video 
                src="videos/VID-20250118-WA0006.mp4" 
                autoPlay 
                loop 
                muted 
                playsInline
                disablePictureInPicture
                className="absolute top-0 left-0 w-full h-full object-fill z-[0] xl:hidden"
              ></video>
              <div className='flex flex-col items-center xl:items-start'>
                <img
                  height={100}
                  width={500}
                  className='md:ml-[-30px] mt-[-16px] z-[1]'
                  src='maxprotect-logo-transparent.png'
                />
                <p className='text-[11px] xm:text-lg md:text-xl text-[#137399] mt-[-10px] font-itim z-[1]'>Your Trusted Partner in Pest Control Solutions.</p>
              </div>
              <div className='flex text-[#f4faff] xl:text-[#11181c] xl:text-left text-[24px] md:text-[33px] font-[410] leading-[28px] md:leading-[37px] font-itim '>
                <p className='z-[0]'>Effective, sustainable, and tailored pest control for homes, businesses, and industries.</p>
              </div>
              <div className='flex flex-col gap-[12px] align-middle xl:items-start font-medium font-itim'>
                <Button
                  color="primary" variant="flat" onPress={onOpen}
                  className='border-2 border-[#5a70a9] bg-[#141f29] px-5 md:px-8 py-[22px] md:py-[26px] text-white text-2xl md:text-3xl rounded-full'
                >BOOK US</Button>
                <Button
                  color="primary" variant="flat" onPress={() => {
                    const whatsappUrl = `https://wa.me/${wa_number}`;
                    window.open(whatsappUrl, "_blank");
                  }}
                  className='border-2 border-[#5a70a9] bg-[#f0c032] px-5 md:px-8 py-[22px] md:py-[26px] text-black text-2xl md:text-3xl rounded-full'
                >Become an affiliate</Button>
              </div>
            </div>
            <div className="relative w-[500px] h-[500px] overflow-hidden hidden xl:block">
              {/* <img 
                className="w-full h-auto absolute inset-y-[-200px]"
                src="commercial.png"
                alt="Cropped Example"
              /> */}
              <video 
                src="videos/VID-20250118-WA0006.mp4" 
                autoPlay 
                loop 
                muted 
                playsInline
                disablePictureInPicture
                className="absolute top-0 left-0 w-full h-full object-cover brightnesss-50"
              ></video>
            </div>
          </div>
        </div>
        
        <div className='h-[10px]'></div>
        
        <div id='resources' className='max-w-[100%] mx-auto bg-[#f4faff] border border-[#8797c2] rounded-full md:rounded-full shadow-md'>
          <div className='flex flex-col justify-between gap-3'>
            <Button onPress={onOpen2} color="primary" startContent={<FaVideo size={100} color='#f0c032' />} variant="bordered" className='bg-[#cde8ff] rounded-full text-[#1f262e] text-3xl font-medium font-itim px-5 py-10 border border-[#5a70a9]'>
              SEE OUR WORK
            </Button>
          </div>
        </div>

        <div id='qrcode' className='max-w-[100%] mx-auto bg-[#f4faff] border border-[#8797c2] rounded-[30px] md:rounded-[50px] shadow-md'>
          <div className='flex flex-col justify-between items-center gap-3 m-6 space-y-2 text-[24px] md:text-[33px] font-medium font-itim'>
            <h3>Scan this QR Code to chat with us on WhatsApp</h3>
            <QRCodeCanvas
              value={whatsappUrlCode}
              size={256}
              bgColor="#ffffff"
              fgColor="#000000"
              level="H"
              includeMargin
            />
          </div>
        </div>

        <div className='h-[20px]'></div>
        
        <div id='services' className='max-w-[93%] mx-auto bg-[#f4faff] mb-0 px-[8px] md:px-[42px] py-[20px] md:py-[40px] border border-[#8797c2] rounded-t-[30px] md:rounded-t-[50px] shadow-md'>
          <div className='flex flex-col justify-between mx-[20px] gap-3'>
            <div>
              <p className='text-3xl md:text-5xl text-[#1f262e] font-medium font-itim'>OUR SERVICES</p>
            </div>
            <div>
              <p className='text-md md:text-2xl font-itim'>We offer a comprehensive range of pest control services designed to address a variety of pest infestations. Our services include:</p>
            </div>
          </div>
        </div>

        <div className='max-w-[93%] mx-auto grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-6'>

          <ServicesList />

        </div>

        <div className='h-[20px]'></div>
        
        <div className='max-w-[93%] mx-auto bg-[#f4faff] mb-0 px-[8px] md:px-[42px] py-[20px] md:py-[40px] border border-[#8797c2] rounded-t-[30px] md:rounded-t-[50px] shadow-md'>
          <div className='flex flex-col justify-between mx-[20px] gap-3'>
            <div>
              <p className='text-3xl md:text-5xl text-[#1f262e] font-medium font-itim'>OUR CLIENTS</p>
            </div>
            <div>
              <p className='text-md md:text-2xl font-itim'>Here are our current clients:</p>
            </div>
          </div>
        </div>

        <div className='w-[114%] left-0 ml-[-7%] bg-[#f4faff] mb-0 px-[8px] md:px-[32px] py-[15px] md:py-[40px] border border-[#ced7e9] overflow-hidden shadow-md'>
          <div className='flex flex-row flex-wrap gap-3 max-w-[88%] mx-auto items-center justify-center'>
            <div>
              <img
                  height={80}
                  width={150}
                  src='client-1.png'
                />
            </div>
            <div>
              <img
                  height={80}
                  width={150}
                  src='client-2.png'
                />
            </div>
            <div>
              <img
                  height={80}
                  width={70}
                  src='client-3.png'
                />
            </div>
            <div>
              <img
                  height={80}
                  width={150}
                  src='client-4.png'
                />
            </div>
            <div>
              <img
                  height={80}
                  width={150}
                  src='client-5.png'
                />
            </div>
            <div>
              <img
                  height={80}
                  width={150}
                  src='client-6.png'
                />
            </div>
            <div>
              <img
                  height={80}
                  width={150}
                  src='client-7.png'
                />
            </div>
            <div>
              <img
                  height={80}
                  width={150}
                  src='client-8.png'
                />
            </div>
            <div>
              <img
                  height={80}
                  width={150}
                  src='client-9.png'
                />
            </div>
          </div>
        </div>

        <div className='h-[20px]'></div>
        
        <div id='about' className='max-w-[93%] mx-auto bg-[#f4faff] mb-0 px-[8px] md:px-[42px] py-[20px] md:py-[40px] border border-[#8797c2] rounded-[30px] md:rounded-[50px] shadow-md'>
          <div className='flex flex-col justify-between mx-[20px] gap-3'>
            <div>
              <p className='text-3xl md:text-5xl text-[#1f262e] font-medium font-itim'>OUR APPROACH</p>
            </div>
            <div>
              <p className='text-md md:text-2xl font-itim'>We believe in a holistic approach to pest control. From detailed inspections to customized plans, we use eco-friendly and effective solutions for long-term results.</p>
            </div>
            <div className='flex flex-row flex-wrap gap-3 md:gap-8 items-center align-middle justify-center my-[10px]'>
              <div className='flex flex-row gap-2'>
                <p className='text-[80px] md:text-[160px] text-[#efc037] leading-none font-irishGrover font-extrabold'>1</p>
                <p className='text-[42px] md:text-[70px] underline underline-[20px] font-medium underline-offset-[20px] md:underline-offset-[45px] leading-none font-itim'>Inspection</p>
              </div>
              <div className='flex flex-row gap-2'>
                <p className='text-[80px] md:text-[160px] text-[#36add2] leading-none font-irishGrover font-extrabold'>2</p>
                <p className='text-[24px] md:text-[50px] underline underline-[20px] font-bold mt-[10px] underline-offset-[20px] md:underline-offset-[45px] leading-none font-itim'>Customized Plan</p>
              </div>
              <div className='flex flex-row gap-2'>
                <p className='text-[80px] md:text-[160px] text-[#3e78b3] leading-none font-irishGrover font-extrabold'>3</p>
                <p className='text-[40px] md:text-[70px] underline underline-[20px] font-medium underline-offset-[20px] md:underline-offset-[45px] leading-none font-itim'>Eradication</p>
              </div>
              <div className='flex flex-row gap-2'>
                <p className='text-[80px] md:text-[160px] text-[#2a3745] leading-none font-irishGrover font-extrabold'>4</p>
                <p className='text-[40px] md:text-[70px] underline underline-[20px] font-medium underline-offset-[20px] md:underline-offset-[45px] leading-none font-itim'>Prevention</p>
              </div>
            </div>
          </div>
        </div>

        <div className='h-[20px]'></div>
        
        <div className='max-w-[93%] mx-auto bg-[#f4faff] mb-0 px-[8px] md:px-[42px] py-[20px] md:py-[40px] border border-[#8797c2] rounded-t-[30px] md:rounded-t-[50px] shadow-md'>
          <div className='flex flex-col justify-between mx-[20px] gap-3'>
            <div>
              <p className='text-3xl md:text-5xl text-[#1f262e] font-medium font-itim'>TESTIMONIALS</p>
            </div>
            <div>
              <p className='text-md md:text-2xl font-itim'>What our clients say:</p>
            </div>
          </div>
        </div>

        <div className='max-w-[93%] mx-auto bg-[#f4faff] mb-0 px-[8px] md:px-[42px] py-[20px] md:py-[40px] border border-[#8797c2] rounded-b-[30px] md:rounded-b-[50px] shadow-md'>
          <div className='flex flex-col justify-between mx-[20px] gap-3'>
            <div>
              <div>
                <div className='flex flex-row flex-wrap gap-8'>
                  <img
                    height={80}
                    width={300}
                    src='sarah-testimonial.png'
                  />
                  <img
                    height={80}
                    width={300}
                    src='james-testimonial.png'
                  />
                  <img
                    height={80}
                    width={300}
                    src='grace-testimonial.png'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div className='h-[20px]'></div>
        
        <div id='careers' className='max-w-[93%] mx-auto bg-[#f4faff] mb-0 px-[8px] md:px-[42px] py-[20px] md:py-[40px] border border-[#8797c2] rounded-[30px] md:rounded-[50px] shadow-md'>
          <div className='flex flex-col justify-between mx-[20px] gap-3'>
            <div>
              <p className='text-3xl md:text-5xl text-[#1f262e] font-medium font-itim'>JOIN OUR TEAM</p>
            </div>
            <div>
              <p className='text-lg md:text-3xl font-itim'>Are you ready to make a difference by creating pest-free environments? MaxProtectKE is looking for passionate technicians to join our growing team.</p>
            </div>
            <div className='flex flex-col w-[100%] items-center align-middle justify-center font-medium font-itim my-3'>
              <Button
                color="primary" variant="flat" onPress={() => {
                    const whatsappUrl = `https://wa.me/${wa_number}`;
                    window.open(whatsappUrl, "_blank");
                  }}
                className='border-2 border-[#5a70a9] bg-[#f8e3a6] px-5 md:px-8 py-[22px] md:py-[26px] text-black text-2xl md:text-3xl rounded-full'
              >APPLY NOW</Button>
            </div>
          </div>
        </div>

        <div className='h-[20px]'></div>
        
        <div id='contact' className='max-w-[93%] mx-auto bg-[#f4faff] mb-0 px-[8px] md:px-[42px] py-[20px] md:py-[40px] border border-[#8797c2] rounded-[30px] md:rounded-[50px] shadow-md'>
          <div className='flex flex-col justify-between mx-[20px] gap-3'>
            <div>
              <p className='text-3xl md:text-5xl text-[#1f262e] font-medium font-itim'>CONTACT US</p>
            </div>
            <div>
              <p className='text-lg md:text-3xl font-itim'>Ready to experience the MaxProtectKE difference? Contact us today.</p>
            </div>
            <div className='flex flex-col w-[100%] items-center align-middle justify-center font-medium font-itim my-3'>
              <Button
                color="primary" variant="flat" onPress={() => {
                    const whatsappUrl = `https://wa.me/${wa_number}`;
                    window.open(whatsappUrl, "_blank");
                  }}
                className='border-2 border-[#5a70a9] bg-[#b5dfef] px-5 md:px-8 py-[22px] md:py-[26px] text-black text-2xl md:text-3xl rounded-full'
              >GET IN TOUCH</Button>
            </div>
          </div>
        </div>

        <div className='h-[20px]'></div>
        
        <div className='max-w-[100%] mx-auto bg-[#ecf4fc] mb-0 px-[8px] md:px-[42px] py-[34px] md:py-[40px] border border-[#8797c2] rounded-[30px] md:rounded-[50px] shadow-md'>
          <div className='flex flex-col justify-between mx-[20px] gap-10'>
            <div className='flex flex-col xl:flex-row gap-3 justify-between items-center xl:items-start'>
              <div>
                <a href="/">
                  <img
                    height={100}
                    width={300}
                    className='md:ml-[-18px] mt-[-16px]'
                    src='maxprotect-logo-transparent.png'
                  />
                </a>
                <div className='text-[14px] uppercase font-itim font-medium  flex flex-col items-center xl:items-start'>
                  <p>+254 111 454545</p>
                  <p>Info@maxprotectke.com</p>
                  <p>@maxprotectke</p>
                </div>
              </div>
              <div className='grid grid-cols-1 md:grid-cols-2 underline my-5 xl:my-0 gap-5 text-xl md:text-2xl font-itim font-medium'>
                <a href='#resources' onClick={() => handleScroll("#resources")}>RESOURCES</a>
                <a href='#services' onClick={() => handleScroll("#services")}>SERVICES</a>
                <a href='#about' onClick={() => handleScroll("#about")}>ABOUT</a>
                <a href='#careers' onClick={() => handleScroll("#careers")}>CAREERS</a>
                <a href='#contact' onClick={() => handleScroll("#contact")}>CONTACT US</a>
              </div>
              <div className='flex'>
                <div className='flex flex-col gap-[12px] align-middle items-end font-medium font-itim'>
                  <Button
                    color="primary" variant="flat" onPress={() => handleScroll("#services")}
                    className='border-2 w-full xl:w-auto border-[#5a70a9] bg-[#141f29] px-4 md:px-5 py-[18px] md:py-[22px] text-white text-lg md:text-2xl rounded-full'
                    >BOOK US</Button>
                  <Button
                    color="primary" variant="flat" onPress={() => {
                    const whatsappUrl = `https://wa.me/${wa_number}`;
                    window.open(whatsappUrl, "_blank");
                  }}
                    className='border-2 w-full xl:w-auto border-[#5a70a9] bg-[#f0c032] px-4 md:px-5 py-[18px] md:py-[22px] text-black text-lg md:text-2xl rounded-full'
                    >Become an affiliate</Button>
                </div>
              </div>
            </div>
            <div>
              <div className='font-itim text-[11px] sm:text-sm md:text-lg font-medium uppercase flex flex-col md:flex-row justify-between border-y-2 border-[#d6ced1] py-[6px]'>
                <p>All Rights Reserved.</p>
                <p>Copyright © 2025 MaxProtectKE.</p>
              </div>
            </div>
          </div>
        </div>

        {/* <div className='h-[50px]'></div> */}

      </div>
    </div>
    </>
  );
}

export default Home;
