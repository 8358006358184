import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/home";
// import Dashboard from "./pages/dashboard";
import Agent from "./pages/agent";
import Promo from "./pages/promo"; // A page that handles params
import Portal from "./pages/portal";
import PrivacyPolicy from "./pages/privacy-policy";
import TermsOfService from "./pages/terms-of-service";
import DataDeletion from "./pages/deletion-request";
import NotFound from "./pages/404";
import Dashboard from "./pages/dashboard";

const App: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      {/* <Route path="/dashboard" element={<Dashboard />} /> */}
      <Route path="/agent" element={<Agent />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/portal" element={<Portal />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms-of-service" element={<TermsOfService />} />
      <Route path="/data-deletion" element={<DataDeletion />} />
      {/* Route with a dynamic parameter */}
      <Route path="/promo/:code" element={<Promo />} />
      {/* Catch-all route for 404 */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default App;
