import React, { useEffect, useState } from "react";

// import {Button, ButtonGroup} from "@nextui-org/button";
import {
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  NavbarMenuToggle,
  NavbarMenu,
  NavbarMenuItem,
  Link,
  Button,
} from "@nextui-org/react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from "@heroui/react";
import { animateScroll as scroll } from 'react-scroll';

const ReactButton = () => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [activeSection, setActiveSection] = useState<string>("");
  const {isOpen, onOpen, onOpenChange} = useDisclosure();

  const links = [
    { href: "#resources", label: "RESOURCES" },
    { href: "#services", label: "SERVICES" },
    { href: "#about", label: "ABOUT" },
    { href: "#careers", label: "CAREERS" },
    { href: "#contact", label: "CONTACT US" },
  ];

  // Handle custom scroll behavior with smooth scroll and offset
  const handleScroll = (href: string) => {
    const target = document.querySelector(href) as HTMLElement;

    if (target) {
      const offsetTop = target.offsetTop - 100; // Offset for fixed navbar
      scroll.scrollTo(offsetTop, { duration: 500, smooth: true }); // Use react-scroll's scrollTo
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    const sections = document.querySelectorAll("div[id]");
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveSection(entry.target.id);
          }
        });
      },
      { threshold: 0.5 }
    );

    sections.forEach((section) => observer.observe(section));
    return () => observer.disconnect();
  }, []);

  return (
    <Navbar 
      onMenuOpenChange={setIsMenuOpen} 
      className='bg-[#f4faff] bg-opacity-50 backdrop-blur-md shadow-lg border border-[#8797c2] sticky top-4 rounded-full max-w-[89%] mx-auto'
      maxWidth='xl'
      classNames={{
        item: [
          "flex",
          "relative",
          "h-full",
          "items-center",
          "data-[active=true]:after:content-['']",
          "data-[active=true]:after:absolute",
          "data-[active=true]:after:bottom-0",
          "data-[active=true]:after:left-0",
          "data-[active=true]:after:right-0",
          "data-[active=true]:after:h-[2px]",
          "data-[active=true]:after:rounded-[2px]",
          "data-[active=true]:after:bg-primary",
        ],
      }}
    >
      <NavbarContent>
        <NavbarMenuToggle
          aria-label={isMenuOpen ? "Close menu" : "Open menu"}
          className="md:hidden"
        />
        <NavbarBrand className='hidden xl:block'>
          <a href="/">
            <img
              height={40}
              width={250}
              className='md:ml-[-10px] borders'
              src='maxprotect-logo-transparent.png'
            />
          </a>
        </NavbarBrand>
        <NavbarBrand className='xl:hidden'>
          <a href="/">
            <img
              height={65}
              width={65}
              className='md:ml-[-10px] borders'
              src='logo-icon.svg'
            />
          </a>
        </NavbarBrand>
      </NavbarContent>

      <NavbarContent className="hidden md:flex gap-4 font-itim font-medium" justify="end">
        {links.map(({ href, label }) => (
          <NavbarItem key={href} isActive={activeSection === href.slice(1)}>
            {activeSection === href.slice(1) ? (
              <Link aria-current="page" href={href} onPress={() => handleScroll(href)}>
                {label}
              </Link>
            ) : (
              <Link color="foreground" href={href} onPress={() => handleScroll(href)}>
                {label}
              </Link>
            )}
          </NavbarItem>
        ))}

        <NavbarItem className="hidden lg:flex ml-6">
          <Button color="primary" variant="flat" onPress={() => handleScroll("#services")} className='bg-[#141f29] rounded-full text-white text-xl font-medium font-itim px-5 py-5 border border-[#5a70a9]'>
            BOOK US
          </Button>
        </NavbarItem>
      </NavbarContent>
      <NavbarContent className='lg:hidden' justify='end'>
        <NavbarItem className="ml-6">
          <Button color="primary" variant="flat" onPress={() => handleScroll("#services")} className='bg-[#141f29] rounded-full text-white text-xl font-medium font-itim px-5 py-5 border border-[#5a70a9]'>
            BOOK US
          </Button>
        </NavbarItem>
      </NavbarContent>
      <NavbarMenu className='mt-[16px] font-itim font-medium'>
        {links.map((item, index) => (
          <NavbarMenuItem key={`${item.label}-${index}`}>
            <Link
              className="w-full"
              color="foreground"
              href={item.href}
              size="lg"
              onPress={() => handleScroll(item.href)}
            >
              {item.label}
            </Link>
          </NavbarMenuItem>
        ))}
      </NavbarMenu>
    </Navbar>
  );
};

export default ReactButton;
