import {Accordion, AccordionItem, Avatar} from "@heroui/react";

const List = [
  {
    uuid: "Bedbug Thermal Fogging",
    name: "Bedbug Thermal Fogging",
    details: "Eliminate bedbugs at all life stages.",
    status: "Bedbug Thermal Fogging",
    price_in_ksh: "Bedbug Thermal Fogging",
    address: "Bedbug Thermal Fogging",
    coordinates: "Bedbug Thermal Fogging",
    scheduled_date: "Bedbug Thermal Fogging",
  },
]

export default function Services() {
  const defaultContent =
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.";

  return (
    <Accordion selectionMode="multiple">
      <AccordionItem
        key="1"
        aria-label="Chung Miller"
        subtitle="scheduled"
        title="Bedbug Thermal Fogging"
      >
        {defaultContent}
      </AccordionItem>
    </Accordion>
  );
}
