// globals.css includes @tailwind directives
// adjust the path if necessary
// import "../../index"
import React from "react";

export default function DataDeletion() {
  return (
    <div>
      <h1>Data Deletion Request</h1>
      <p>To request for deletion of your data on our systems, click on the chat with us whatsapp button to request that or click this link: <a href="https://maxprotectke.com/#contact">https://maxprotectke.com/#contact</a></p>
    </div>
  );
}