import { useParams } from "react-router-dom";
import React, { useState, useEffect, } from "react";
import '../../App.css';
import {
  Button,
} from "@nextui-org/react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from "@heroui/react";

const PROMO_STORAGE_KEY = "promo_codes";
const EXPIRY_DAYS = 30;

const Promo: React.FC = () => {
  const [activeCode, setActiveCode] = useState<string>("");
  const { code } = useParams<{ code?: string }>();
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [openState, setOpenState] = useState(true);

  useEffect(() => {
    const now = Date.now();

    // Retrieve promo codes from localStorage
    const storedPromoCodes = JSON.parse(localStorage.getItem(PROMO_STORAGE_KEY) || "[]");

    // Filter out expired promo codes
    const validPromoCodes = storedPromoCodes.filter((promo: { code: string; expiry: number }) => promo.expiry > now);

    if (validPromoCodes.length > 0) {
      // Use the first valid promo code
      setActiveCode(validPromoCodes[0].code.toUpperCase());
    } else if (code) {
      // If there's a new code in the URL and no valid ones exist, save it
      const expiry = now + EXPIRY_DAYS * 24 * 60 * 60 * 1000; // 30 days in milliseconds
      const newPromoCode = { code, expiry };

      localStorage.setItem(PROMO_STORAGE_KEY, JSON.stringify([newPromoCode]));
      setActiveCode(code.toUpperCase());
    }
  }, [code]);


  const onWebsite = () => {
    window.location.href = "/";
  };

  const onWhatsApp = () => {
    const phoneNumber = "254784470922"; // Replace with your actual WhatsApp number
    const message = encodeURIComponent(`Hello, I'm interested in fumigation services. \nMy promo code is: ${activeCode}`);
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${message}`;

    window.open(whatsappUrl, "_blank");
  };

  return (
    <div>
      <Modal isOpen={isOpen || openState} onOpenChange={onOpenChange}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">Welcome to MaxProtectKE</ModalHeader>
              <ModalBody>
                <p>
                  Would you like to visit our website or chat with us on WhatsApp?
                </p>
                <p>
                  Your promo code is: <span className="font-bold uppercase">{activeCode}</span>
                </p>
                <p className="italic font-light">This promo code is saved and persisted for 30 days.</p>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onWebsite}>
                  Website
                </Button>
                <Button color="primary" onPress={onWhatsApp}>
                  WhatsApp
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </div>
  );
};

export default Promo;
