// globals.css includes @tailwind directives
// adjust the path if necessary
// import "../../index"
import React from "react";
import MapPicker from "../../components/MapPicker";

export default function Agent() {
  const handleLocationSelected = (coords) => {
    console.log("Final Coordinates:", coords);
    alert(coords);
    // You can send this to your backend or state management
  };
  return (
    <div>
      <div>
        <h1>Agent Maxie coming soon.</h1>
        <MapPicker onLocationSelected={handleLocationSelected} />
      </div>
    </div>
  );
}