import React, { useState, useRef, useEffect } from "react";
import '../../App.css';
import {
  Button,
} from "@nextui-org/react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  RadioGroup, 
  Radio,
  Link, 
  User, 
  Chip, 
  cn,
  ScrollShadow,
  InputOtp,
} from "@heroui/react";

import { QRCodeCanvas } from 'qrcode.react';

import { FaVideo } from "react-icons/fa6";
import { TiPin } from "react-icons/ti";
import { FaArrowRight } from "react-icons/fa";
import { FaArrowLeft } from "react-icons/fa6";

import { animateScroll as scroll } from 'react-scroll';

import DashboardNav from "../../components/DashboardNav";
import Services from "../../components/Services";

import {Tabs, Tab, Input, Card, CardBody} from "@heroui/react";

import axios from "axios";

export const EyeSlashFilledIcon = (props) => {
  return (
    <svg
      aria-hidden="true"
      fill="none"
      focusable="false"
      height="1em"
      role="presentation"
      viewBox="0 0 24 24"
      width="1em"
      {...props}
    >
      <path
        d="M21.2714 9.17834C20.9814 8.71834 20.6714 8.28834 20.3514 7.88834C19.9814 7.41834 19.2814 7.37834 18.8614 7.79834L15.8614 10.7983C16.0814 11.4583 16.1214 12.2183 15.9214 13.0083C15.5714 14.4183 14.4314 15.5583 13.0214 15.9083C12.2314 16.1083 11.4714 16.0683 10.8114 15.8483C10.8114 15.8483 9.38141 17.2783 8.35141 18.3083C7.85141 18.8083 8.01141 19.6883 8.68141 19.9483C9.75141 20.3583 10.8614 20.5683 12.0014 20.5683C13.7814 20.5683 15.5114 20.0483 17.0914 19.0783C18.7014 18.0783 20.1514 16.6083 21.3214 14.7383C22.2714 13.2283 22.2214 10.6883 21.2714 9.17834Z"
        fill="currentColor"
      />
      <path
        d="M14.0206 9.98062L9.98062 14.0206C9.47062 13.5006 9.14062 12.7806 9.14062 12.0006C9.14062 10.4306 10.4206 9.14062 12.0006 9.14062C12.7806 9.14062 13.5006 9.47062 14.0206 9.98062Z"
        fill="currentColor"
      />
      <path
        d="M18.25 5.74969L14.86 9.13969C14.13 8.39969 13.12 7.95969 12 7.95969C9.76 7.95969 7.96 9.76969 7.96 11.9997C7.96 13.1197 8.41 14.1297 9.14 14.8597L5.76 18.2497H5.75C4.64 17.3497 3.62 16.1997 2.75 14.8397C1.75 13.2697 1.75 10.7197 2.75 9.14969C3.91 7.32969 5.33 5.89969 6.91 4.91969C8.49 3.95969 10.22 3.42969 12 3.42969C14.23 3.42969 16.39 4.24969 18.25 5.74969Z"
        fill="currentColor"
      />
      <path
        d="M14.8581 11.9981C14.8581 13.5681 13.5781 14.8581 11.9981 14.8581C11.9381 14.8581 11.8881 14.8581 11.8281 14.8381L14.8381 11.8281C14.8581 11.8881 14.8581 11.9381 14.8581 11.9981Z"
        fill="currentColor"
      />
      <path
        d="M21.7689 2.22891C21.4689 1.92891 20.9789 1.92891 20.6789 2.22891L2.22891 20.6889C1.92891 20.9889 1.92891 21.4789 2.22891 21.7789C2.37891 21.9189 2.56891 21.9989 2.76891 21.9989C2.96891 21.9989 3.15891 21.9189 3.30891 21.7689L21.7689 3.30891C22.0789 3.00891 22.0789 2.52891 21.7689 2.22891Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const EyeFilledIcon = (props) => {
  return (
    <svg
      aria-hidden="true"
      fill="none"
      focusable="false"
      height="1em"
      role="presentation"
      viewBox="0 0 24 24"
      width="1em"
      {...props}
    >
      <path
        d="M21.25 9.14969C18.94 5.51969 15.56 3.42969 12 3.42969C10.22 3.42969 8.49 3.94969 6.91 4.91969C5.33 5.89969 3.91 7.32969 2.75 9.14969C1.75 10.7197 1.75 13.2697 2.75 14.8397C5.06 18.4797 8.44 20.5597 12 20.5597C13.78 20.5597 15.51 20.0397 17.09 19.0697C18.67 18.0897 20.09 16.6597 21.25 14.8397C22.25 13.2797 22.25 10.7197 21.25 9.14969ZM12 16.0397C9.76 16.0397 7.96 14.2297 7.96 11.9997C7.96 9.76969 9.76 7.95969 12 7.95969C14.24 7.95969 16.04 9.76969 16.04 11.9997C16.04 14.2297 14.24 16.0397 12 16.0397Z"
        fill="currentColor"
      />
      <path
        d="M11.9984 9.14062C10.4284 9.14062 9.14844 10.4206 9.14844 12.0006C9.14844 13.5706 10.4284 14.8506 11.9984 14.8506C13.5684 14.8506 14.8584 13.5706 14.8584 12.0006C14.8584 10.4306 13.5684 9.14062 11.9984 9.14062Z"
        fill="currentColor"
      />
    </svg>
  );
};

const ACCOUNTS_STORAGE_KEY = "accounts";
const API_ENDPOINT = "http://localhost:4000/";
// Retrieve promo codes from localStorage
const storedAccounts = JSON.parse(localStorage.getItem(ACCOUNTS_STORAGE_KEY) || "[]");

export default function Dashboard() {
  const [accounts, setAccounts] = React.useState(storedAccounts);

  function Login() {
    const [selected, setSelected] = React.useState("login");
    const [loginErrorMessage, setLoginErrorMessage] = React.useState("");
    const [verifyErrorMessage, setVerifyErrorMessage] = React.useState("");

    const [newPassOpen, setNewPassOpen] = React.useState(false);

    const [sendCodeBtn, setSendCodeBtn] = React.useState("Send Code");
    const timerRef = useRef<NodeJS.Timeout | null>(null); // To store the timer reference

    const { isOpen, onOpen, onClose, onOpenChange } = useDisclosure();
    const { isOpen: isOpen2, onOpen: onOpen2, onClose: onClose2, onOpenChange: onOpenChange2 } = useDisclosure();

    const [isCreatingAccount, setIsCreatingAccount] = React.useState(false);
    const [isLoggingIn, setIsLoggingIn] = React.useState(false);
    const [isVerifying, setIsVerifying] = React.useState(false);
    const [isSendingCode, setIsSendingCode] = React.useState(false);
    const [isPasswordChanging, setIsPasswordChanging] = React.useState(false);

    const [isPasswordVisible, setIsPasswordVisible] = React.useState(false);

    const [phone, setPhone] = useState("");
    const [name, setName] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const [passwordNew, setPasswordNew] = useState("");
    const [confirmPasswordNew, setConfirmPasswordNew] = useState("");

    const [otp, setOTP] = useState("");

    const toggleVisibility = () => setIsPasswordVisible(!isPasswordVisible);

    const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setPhone(e.target.value.slice(0, 9));
    };

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setName(e.target.value.slice(0, 255));
    };

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setPassword(e.target.value.slice(0, 255));
    };

    const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setConfirmPassword(e.target.value.slice(0, 255));
    };

    const handlePasswordChangeNew = (e: React.ChangeEvent<HTMLInputElement>) => {
      setPasswordNew(e.target.value.slice(0, 255));
    };

    const handleConfirmPasswordChangeNew = (e: React.ChangeEvent<HTMLInputElement>) => {
      setConfirmPasswordNew(e.target.value.slice(0, 255));
    };

    const handleOTPChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setOTP(e.target.value.slice(0, 6));
    };


    const afterSend = () => {
      let seconds = 59;
      setSendCodeBtn("00:59"); // Start with 59 seconds

      timerRef.current = setInterval(() => {
        const min = Math.floor(seconds / 60)
          .toString()
          .padStart(2, "0"); // Always 2 digits
        const sec = (seconds % 60).toString().padStart(2, "0");

        setSendCodeBtn(`${min}:${sec}`);
        seconds--;

        if (seconds < 0) {
          clearInterval(timerRef.current as NodeJS.Timeout);
          setSendCodeBtn("Send Code"); // Reset to original
        }
      }, 1000);
    };

    const sendCodeToPhone = async () => {
      setIsSendingCode(true);
      try {
        const response = await axios.post(API_ENDPOINT + "sendCode", { phone: "+254" + phone, });
        console.log("Code sent:", response.data);
      } catch (error) {
        alert("Error sending code.");
        console.error("Error sending code:", error);
      }
      setIsSendingCode(false);
    };

    const verifyPhone = async () => {
      setIsVerifying(true);
      setVerifyErrorMessage("");
      try {
        const response = await axios.post(API_ENDPOINT + "verifyCode", { phone: "+254" + phone, code: otp });
        console.log("Phone verified:", response.data);
        if(response.data.verified){
          if(password === ""){
            setNewPassOpen(true);
          } else {
            alert("Verified. Logging you in...");
            await loginAccount();
          }
          setOTP("");
        } else {
          setVerifyErrorMessage("Failed Verification. Try Again.");
        }
      } catch (error) {
        alert("Error verifying. Try Again!");
        console.error("Error verifying phone:", error);
      }
      setIsVerifying(false);
    };

    const createAccount = async () => {
      setIsCreatingAccount(true);
      setLoginErrorMessage("");
      setVerifyErrorMessage("");
      try {
        const response = await axios.post(API_ENDPOINT + "createCustomer", { 
          name: name, phone: "+254" + phone, address: "", password: password, mfa: false
         });
        console.log("Created Account:", response.data);
        if(response.data.account){
          sendCodeToPhone();
          onOpen();
          afterSend();
        }
      } catch (error) {
        alert("Account not created. Try Again!");
        console.error("Error creating account:", error);
      }
      setIsCreatingAccount(false);
    };

    const loginAccount = async () => {
      setIsLoggingIn(true);
      setLoginErrorMessage("");
      setVerifyErrorMessage("");
      try {
        const response = await axios.post(API_ENDPOINT + "loginCustomer", { 
          phone: "+254" + phone, password: password
        });

        console.log("Logging in Account:", response.data);
        if(response.data.login){
          localStorage.setItem(ACCOUNTS_STORAGE_KEY, JSON.stringify([response.data.account]));
          setAccounts(JSON.stringify([response.data.account]));
        } else {
          setLoginErrorMessage(response.data.message);
        }
        if(response.data.message === "Account Not Verified.") {
          // setLoginErrorMessage(response.data.message);
          onOpen();
        }
      } catch (error) {
        alert("Error logging in, try Again!");
        console.error("Error Logging in account:", error);
      }
      setIsLoggingIn(false);
    };

    const changePassword = async () => {
      setIsPasswordChanging(true);
      try {
        const response = await axios.post(API_ENDPOINT + "changePassword", { phone: "+254" + phone, password: passwordNew });
        console.log("Change password:", response.data);
        if(response.data.changed){
          alert("Password Changed Successfully. Login Now!");
        } else {
          alert("Password not changed. Try Again!");
        }
      } catch (error) {
        alert("Password not changed. Try Again!");
        console.error("Error changing password:", error);
      }
      setNewPassOpen(false);
      setIsPasswordChanging(false);
    };

    return (
      <div className="flex ">
        <Modal isOpen={isOpen2} onOpenChange={onOpenChange2} placement="center">
          <ModalContent>
            {newPassOpen ? 
            // (onClose2) => (
              <>
                <ModalHeader className="flex flex-col gap-1">Set your New Password</ModalHeader>
                <ModalBody>
                  <Input
                    isRequired
                    label="New Password"
                    placeholder="Enter your new password"
                    endContent={
                      <button
                        aria-label="toggle password visibility"
                        className="focus:outline-none"
                        type="button"
                        onClick={toggleVisibility}
                      >
                        {isPasswordVisible ? (
                          <EyeSlashFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                        ) : (
                          <EyeFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                        )}
                      </button>
                    }
                    type={isPasswordVisible ? "text" : "password"}
                    value={passwordNew} onChange={handlePasswordChangeNew} 
                  />
                  <Input
                    isRequired
                    label="Confirm New Password"
                    placeholder="Confirm your new password"
                    endContent={
                      <button
                        aria-label="toggle password visibility"
                        className="focus:outline-none"
                        type="button"
                        onClick={toggleVisibility}
                      >
                        {isPasswordVisible ? (
                          <EyeSlashFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                        ) : (
                          <EyeFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                        )}
                      </button>
                    }
                    type={isPasswordVisible ? "text" : "password"}
                    value={confirmPasswordNew} onChange={handleConfirmPasswordChangeNew} 
                  />                  
                </ModalBody>
                <ModalFooter>
                  <Button color="danger" variant="light" onPress={onClose2}>
                    Close
                  </Button>
                  <Button isLoading={isPasswordChanging} isDisabled={passwordNew.length < 1 || confirmPasswordNew.length < 1 || passwordNew !== confirmPasswordNew} color="primary" onPress={() => {
                    changePassword();
                    onClose2();
                  }}>
                    Verify
                  </Button>
                </ModalFooter>
              </>
            // )
             :
            // (onClose2) => (
              <>
                <ModalHeader className="flex flex-col gap-1">Verify your account to Change Password</ModalHeader>
                <ModalBody>
                  { verifyErrorMessage.length > 3 ?
                    <p className="text-center text-red-600 text-small">
                      { verifyErrorMessage }
                    </p>
                    : <></>
                  }
                  <div className="flex flex-row gap-2">
                    <Input className="w-[30%]" isRequired isDisabled label="Country" value="🇰🇪️ +254" type="code" />
                    <Input className="w-[70%]" isRequired label="Phone" placeholder="Enter your phone" type="tel" value={phone} onChange={handlePhoneChange} />
                  </div>
                  <Button isLoading={isSendingCode} isDisabled={(phone.length < 9 || sendCodeBtn !== "Send Code")} color="primary" onPress={async () => {
                    await sendCodeToPhone();
                    // 111278514
                    afterSend();
                  }}>
                    {sendCodeBtn}
                  </Button>
                  <div className="flex w-[100%] align-middle justify-center items-center">
                    <InputOtp
                      className="flex w-[100%] align-middle justify-center items-center"
                      isRequired
                      variant="faded"
                      aria-label="OTP input field"
                      length={6}
                      name="otp"
                      placeholder="Enter code"
                      value={otp} onChange={handleOTPChange} 
                    />
                  </div>
                  {/* <p className="italic font-light">This promo code is saved and persisted for 30 days.</p> */}
                </ModalBody>
                <ModalFooter>
                  <Button color="danger" variant="light" onPress={onClose2}>
                    Close
                  </Button>
                  <Button isLoading={isVerifying} isDisabled={otp.length < 6} color="primary" onPress={() => {
                    verifyPhone();
                  }}>
                    Verify
                  </Button>
                </ModalFooter>
              </>
            // )
            }
          </ModalContent>
        </Modal>


        <Modal isOpen={isOpen} onOpenChange={onOpenChange} placement="center">
          <ModalContent>
            {(onClose) => (
              <>
                <ModalHeader className="flex flex-col gap-1">Verify your account through WhatsApp</ModalHeader>
                <ModalBody>
                  { verifyErrorMessage.length > 3 ?
                    <p className="text-center text-red-600 text-small">
                      { verifyErrorMessage }
                    </p>
                    : <></>
                  }
                  <div className="flex flex-row gap-2">
                    <Input className="w-[30%]" isRequired isDisabled label="Country" value="🇰🇪️ +254" type="code" />
                    <Input className="w-[70%]" isRequired isDisabled label="Phone" placeholder="Enter your phone" type="tel" value={phone} onChange={handlePhoneChange} />
                  </div>
                  <Button isLoading={isSendingCode} isDisabled={(phone.length < 9 || sendCodeBtn !== "Send Code")} color="primary" onPress={async () => {
                    await sendCodeToPhone();
                    // 111278514
                    afterSend();
                  }}>
                    {sendCodeBtn}
                  </Button>
                  <div className="flex w-[100%] align-middle justify-center items-center">
                    <InputOtp
                      className="flex w-[100%] align-middle justify-center items-center"
                      isRequired
                      variant="faded"
                      aria-label="OTP input field"
                      length={6}
                      name="otp"
                      placeholder="Enter code"
                      value={otp} onChange={handleOTPChange} 
                    />
                  </div>
                  {/* <p className="italic font-light">This promo code is saved and persisted for 30 days.</p> */}
                </ModalBody>
                <ModalFooter>
                  <Button color="danger" variant="light" onPress={onClose}>
                    Close
                  </Button>
                  <Button isLoading={isVerifying} isDisabled={otp.length < 6} color="primary" onPress={() => {
                    verifyPhone();
                    onClose();
                  }}>
                    Verify
                  </Button>
                </ModalFooter>
              </>
            )}
          </ModalContent>
        </Modal>

        <div className="flex flex-col w-full items-center h-screen mt-12">
          <Card className="max-w-full w-[340px] h-[450px]">
            <CardBody className="overflow-hidden">
              <Tabs
                fullWidth
                aria-label="Tabs form"
                selectedKey={selected}
                size="md"
                onSelectionChange={(value) => {
                  if(value === "login"){setSelected("login")} 
                  else if(value === "sign-up"){setSelected("sign-up")}
                }}
              >
                <Tab key="login" title="Login">
                  <form className="flex flex-col gap-4">
                    { loginErrorMessage.length > 3 ?
                      <p className="text-center text-red-600 text-small">
                        { loginErrorMessage }
                      </p>
                      : <></>
                    }
                    <div className="flex flex-row gap-2">
                      <Input className="w-[30%]" isRequired isDisabled label="Country" value="🇰🇪️ +254" type="code" />
                      <Input className="w-[70%]" isRequired label="Phone" placeholder="Enter your phone" type="tel" value={phone} onChange={handlePhoneChange} />
                    </div>
                    <Input
                      isRequired
                      label="Password"
                      placeholder="Enter your password"
                      endContent={
                        <button
                          aria-label="toggle password visibility"
                          className="focus:outline-none"
                          type="button"
                          onClick={toggleVisibility}
                        >
                          {isPasswordVisible ? (
                            <EyeSlashFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                          ) : (
                            <EyeFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                          )}
                        </button>
                      }
                      type={isPasswordVisible ? "text" : "password"}
                      value={password} onChange={handlePasswordChange} 
                    />
                    <div className="space-y-1">
                      <p className="text-center text-small">
                        Forgot your Password?{" "}
                        <Link className="cursor-pointer" size="sm" onPress={() => {
                          setPassword("");
                          onOpen2();
                        }}>
                          Reset Password
                        </Link>
                      </p>
                      <p className="text-center text-small">
                        Need to create an account?{" "}
                        <Link className="cursor-pointer" size="sm" onPress={() => setSelected("sign-up")}>
                          Sign up
                        </Link>
                      </p>
                    </div>
                    <div className="flex gap-2 justify-end">
                      <Button isLoading={isLoggingIn} fullWidth color="primary" onPress={loginAccount} isDisabled={phone.length < 9 || password.length < 1}>
                        Login
                      </Button>
                    </div>
                  </form>
                </Tab>
                <Tab key="sign-up" title="Sign up">
                  <form className="flex flex-col gap-4 h-[300px]">
                    <Input isRequired label="Name" placeholder="Enter your name" type="name" value={name} onChange={handleNameChange}  />
                    <div className="flex flex-row gap-2">
                      <Input className="w-[30%]" isRequired isDisabled label="Country" value="🇰🇪️ +254" type="code" />
                      <Input className="w-[70%]" isRequired label="WhatsApp Phone" placeholder="Enter your phone" type="tel" value={phone} onChange={handlePhoneChange}  />
                    </div>
                    <Input
                      isRequired
                      label="Password"
                      placeholder="Enter your password"
                      endContent={
                        <button
                          aria-label="toggle password visibility"
                          className="focus:outline-none"
                          type="button"
                          onClick={toggleVisibility}
                        >
                          {isPasswordVisible ? (
                            <EyeSlashFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                          ) : (
                            <EyeFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                          )}
                        </button>
                      }
                      type={isPasswordVisible ? "text" : "password"}
                      value={password} onChange={handlePasswordChange} 
                    />
                    <Input
                      isRequired
                      label="Confirm Password"
                      placeholder="Confirm your password"
                      endContent={
                        <button
                          aria-label="toggle password visibility"
                          className="focus:outline-none"
                          type="button"
                          onClick={toggleVisibility}
                        >
                          {isPasswordVisible ? (
                            <EyeSlashFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                          ) : (
                            <EyeFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                          )}
                        </button>
                      }
                      type={isPasswordVisible ? "text" : "password"}
                      value={confirmPassword} onChange={handleConfirmPasswordChange} 
                    />
                    <p className="text-center text-small">
                      Already have an account?{" "}
                      <Link className="cursor-pointer" size="sm" onPress={() => setSelected("login")}>
                        Login
                      </Link>
                    </p>
                    <div className="flex gap-2 justify-end">
                      <Button isLoading={isCreatingAccount} fullWidth color="primary" onPress={createAccount} isDisabled={phone.length < 9 || name.length < 1 || password.length < 1 || confirmPassword.length < 1 || password !== confirmPassword}>
                        Sign up
                      </Button>
                    </div>
                  </form>
                </Tab>
              </Tabs>
            </CardBody>
          </Card>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className='fixed top-0 left-0 w-[100%] h-[4890px] z-[-50] overflow-x-hidden'>
        <div className=''>
          <div className='absolute rounded-[3000px] w-[400px] h-[400px] left-0 mt-[0px] ml-[-375px]'>
            <img
              height={400}
              width={400}
              className=''
              src='sphere.png'
            />
          </div>

          <div className='absolute border-[80px] border-[#f8e3a6] rounded-[3000px] w-[980px] h-[980px] right-0 mt-[-300px] mr-[-250px]'></div>
          <div className='absolute border-[80px] border-[#b5dfef] rounded-[3000px] w-[980px] h-[980px] left-0 mt-[300px] ml-[-300px]'></div>

          <div className='absolute w-[250px] h-[250px] left-0 mt-[350px] md:mt-[250px] transform rotate-[70deg] md:rotate-[-70deg] ml-[-30px]'>
            <img
              height={400}
              width={400}
              className='filter grayscale brightness-0'
              src='footer-bug-left.png'
            />
          </div>

          <div className='absolute w-[600px] h-[400px] right-0 mt-[60px] md:mt-[230px] transform rotate-[-90deg] md:rotate-[70deg] mr-[-170px]'>
            <img
              height={400}
              width={600}
              className='filter grayscale brightness-0'
              src='footer-bug-right.png'
            />
          </div>
        </div>
      </div>

      { (accounts.length > 0) ? 
      <div>
        <DashboardNav />
        <div className='h-6'></div>
        <div className="App scroll-smooth"></div>

        <div className='max-w-[88%] m-[20px] mx-auto space-y-[20px]'>
          {/* <div className='bg-[#ecf4fc] mb-0 p-[16px] border border-[#8797c2] rounded-[30px]'>Navbar</div> */}
          <div className='bg-[#f4faff] py-[20px] overflow-hidden mb-0 px-[20px] md:px-[30px] xl:px-[42px] border border-[#8797c2] rounded-[30px] md:rounded-[50px] shadow-md'>
            <p className='text-2xl md:text-4xl text-[#1f262e] font-medium font-itim'>Your Services</p>
            <Services />
          </div>
          
          <div className='h-[5px]'></div>

          <div className='bg-[#f4faff] py-[20px] overflow-hidden mb-0 px-[20px] md:px-[30px] xl:px-[42px] border border-[#8797c2] rounded-[30px] md:rounded-[50px] shadow-md'>
            <p>Account</p>
          </div>
          
          <div className='h-[10px]'></div>
        </div>
      </div>
      :
      <Login />
      }
    </div>
  );
}