import { MapContainer, TileLayer, Marker, useMapEvents, useMap } from "react-leaflet";
import { useEffect, useState } from "react";
import "leaflet/dist/leaflet.css";
import L, { LatLngExpression } from "leaflet";

// Custom marker icon
const markerIcon = new L.Icon({
  iconUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
});

// Define type for coordinates
type Coordinates = [number, number] | null;

interface MapPickerProps {
  onLocationSelected: (coords: [number, number]) => void;
}

function MapPicker({ onLocationSelected }: MapPickerProps) {
  const [position, setPosition] = useState<Coordinates>(null);
  const [userLocation, setUserLocation] = useState<Coordinates>(null);
  const [loading, setLoading] = useState(true);

  // Fetch user's current location on mount
  useEffect(() => {
    getCurrentLocation();
  }, []);

  // Function to get current location
  const getCurrentLocation = () => {
    setLoading(true);
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          const { latitude, longitude } = pos.coords;
          setUserLocation([latitude, longitude]);
          setPosition([latitude, longitude]); // Set initial marker
          setLoading(false);
        },
        (err) => {
          console.error("Error getting location:", err);
          alert("Unable to fetch current location.");
          setLoading(false);
        }
      );
    } else {
      alert("Geolocation is not supported by your browser.");
      setLoading(false);
    }
  };

  // Custom Hook to handle map clicks
  function LocationMarker() {
    useMapEvents({
      click(e) {
        const { lat, lng } = e.latlng;
        setPosition([lat, lng]);
      },
    });

    return position ? <Marker position={position as LatLngExpression} icon={markerIcon} /> : null;
  }

  // Move map view to user's location
  function RecenterMap() {
    const map = useMap();
    useEffect(() => {
      if (userLocation) {
        map.setView(userLocation as LatLngExpression, 15);
      }
    }, [userLocation, map]);
    return null;
  }

  const handleConfirm = (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent map click
    if (position) {
      onLocationSelected(position);
      alert(`Location Selected:\nLatitude: ${position[0]}, Longitude: ${position[1]}`);
    } else {
      alert("Please choose a location on the map.");
    }
  };

  return (
    <div style={{ height: "80vh" }}>
      {/* Button Controls */}
        <div
          style={{
            // position: "absolute",
            // top: "10px",
            // left: "50%",
            // transform: "translateX(-50%)",
            // zIndex: 1000,
            pointerEvents: "none", // Prevents propagation
          }}
        >
          <button
            onClick={handleConfirm}
            style={{
              padding: "10px 20px",
              backgroundColor: "#007bff",
              color: "white",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              pointerEvents: "auto", // Re-enables button clicks
              marginRight: "10px",
            }}
          >
            Confirm Location
          </button>
          <button
            onClick={(e) => {
              e.stopPropagation(); // Prevent map click
              getCurrentLocation();
            }}
            style={{
              padding: "10px 20px",
              backgroundColor: "#28a745",
              color: "white",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              pointerEvents: "auto",
            }}
          >
            Use My Location
          </button>
        </div>
      <MapContainer
        center={userLocation || [0, 0]}
        zoom={userLocation ? 15 : 2}
        scrollWheelZoom={true}
        style={{ height: "100%", width: "100%" }}
      >
        <TileLayer
          attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <RecenterMap />
        <LocationMarker />

        {/* Loading Indicator */}
        {loading && (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              background: "rgba(0, 0, 0, 0.6)",
              padding: "20px 30px",
              borderRadius: "8px",
              color: "white",
              fontSize: "1.5rem",
              zIndex: 1000,
              textAlign: "center",
            }}
          >
            Loading...
          </div>
        )}
      </MapContainer>
    </div>
  );
}

export default MapPicker;
